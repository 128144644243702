<template>
    <div>
        <div class="zx-banner-bar" id="index">
            <div class="swiper-container swiper-container1">
                <div class="swiper-wrapper ">
                    <div class="swiper-slide">
                        <div class="zx-banner-list">
                            <img class="banner-1" src="../../assets/banner.png" alt="">
                            <img class="banner-2" src="../../assets/banner-2.png" alt="">
                            <div class="banner-text">
                                <h3>数据生产要素流通的<span>守护者</span></h3>
                                <p>推动数据要素聚合，并通过隐私计算技术构建商业银行间数据安全流通网络，为金融机构提供安全可靠的数据要素服务，持续深耕金融行业场景化应用。</p>
                                <div class="button-bar">
                                    <a  @click="$router.push('/aboutUs');$parent.curNavName = 'aboutUs'">了解更多</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 如果需要导航按钮 -->
<!--                <div class="swiper-button-prev swiper-button-prev1"></div>-->
<!--                <div class="swiper-button-next swiper-button-next1"></div>-->
            </div>
        </div>
        <div class="zx-product-bar" id="product">
            <div class="zx-product-box">
                <div class="zx-product-title">
                    <h2>产品</h2>
                    <h3>Product</h3>
                </div>
                <div class="zx-new-product-bar">

                    <div class="zx-new-product-list">
                        <img src="../../assets/product/product-icon-1.png" style="margin-top: 84px; margin-bottom: 50px;" width="75" alt="">
                        <h3>慧聚</h3>
                        <p>数据要素服务平台</p>
                        <a @click="$router.push('/producthj');$parent.curNavName = 'product'">查看详情</a>
                    </div>
                    <div class="zx-new-product-list">
                        <img src="../../assets/product/product-icon-2.png" style="margin-top: 101px;margin-bottom: 60px;"  width="80" alt="">
                        <h3>慧通</h3>
                        <p>数据安全流通平台</p>
                        <a @click="$router.push('/productht');$parent.curNavName = 'product'">查看详情</a>
                    </div>
                    <div class="zx-new-product-list">
                        <img src="../../assets/product/1673791398619.png" style="margin-top: 95px;margin-bottom: 30px;"  width="70" alt="">
                        <h3>慧治</h3>
                        <p>数据综合治理平台</p>
                        <a  @click="$router.push('/producthz');$parent.curNavName = 'product'">查看详情</a>
                    </div>
                </div>
<!--                <ul class="zx-product-sub-nav">-->
<!--                    <li>慧聚</li>-->
<!--                    <li>慧通</li>-->
<!--                    <li>慧知</li>-->
<!--                </ul>-->
<!--                <ul class="zx-product-list">-->
<!--                    <li>-->
<!--                        <img src="../../assets/product-banner-1.png" alt="">-->
<!--                        <h3>数据要素服务平台</h3>-->
<!--                        <p>慧聚定位为数据要素服务平台，为金融机构提供便捷高效的数据接入能力，安全可靠的数据管理能力以及更丰富的运营管理功能。</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <img src="../../assets/product-banner-2.png" alt="">-->
<!--                        <h3>数据安全流通平台</h3>-->
<!--                        <p>慧通多方安全计算平台基于多方安全计算（MPC）、密码学、分布式技术等，在满足数据隐私安全和政策法规的要求下打破数据壁垒， 在实现数据深度挖掘的同时保护原始数据的隐私性。</p>-->
<!--                    </li>-->
<!--                    <li>-->
<!--                        <img src="../../assets/product-banner-3.png" alt="">-->
<!--                        <h3>数据智能分析平台</h3>-->
<!--                        <p>慧知围绕数字化升级，应用大数据、人工智能、云计算等技术挖掘数据深层价值，构建金融领域知识图谱系统，推动金融反欺诈、风控等领域的智能化应用。</p>-->
<!--                    </li>-->
<!--                </ul>-->
<!--                <a class="more-btn">-->
<!--                    MORE-->
<!--                    <svg class="array" width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                        <path d="M0 6.5H16" stroke="black"/>-->
<!--                        <path d="M6 6.5H22" stroke="white"/>-->
<!--                        <path d="M17 1L22 6.2963L17 12" stroke="white"/>-->
<!--                    </svg>-->

<!--                </a>-->
            </div>
        </div>

        <div class="zx-solution-bar" id="solution">
            <div class="zx-solution-box">
                <div class="zx-solution-title">
                    <h2>解决方案</h2>
                    <h3>Solution</h3>
                </div>
                <div class="zx-solution-banner">
                    <div class="zx-solution-block">
                        <div class="zx-solution-content">
                            <img src="../../assets/solution-banner1.png" alt="">
                            <div>
                                <h3>银行账户风险联防联控</h3>
                                <p>“银账保”账户风险防控平台，是围绕人民银行监管要求，以降低涉案风险账户为目的，为人民银行及商业银行提供的区域级账户风险联防联控的一站式服务平台，并支持Web/API多形态接入。</p>
                                <div>
                                    <a @click="$router.push('/solution');$parent.curNavName = 'solution'">
                                        <svg width="23" height="13" viewBox="0 0 23 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M0 6L22 6" stroke="white"/>
                                            <path d="M17 1L22 6.2963L17 12" stroke="white"/>
                                        </svg>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="zx-partners-bar" style="margin-top: 80px;">
            <div class="zx-partners-box" style="
        margin-bottom: 43px; border-bottom: none">
                <div class="zx-partners-title">
                    <h2>客户案例</h2>
                    <h3>Customer stories</h3>
                </div>

                <div class="zx-new-partners-list-bar">
                    <div class="zx-new-partners-list">
                        <p>数据要素服务平台案例</p>
                        <a  @click="$router.push('/partners');$parent.curNavName = 'partners'">查看详情</a>
                    </div>
                    <div class="zx-new-partners-list">
                        <p>账户风险防控平台案例</p>
                        <a @click="$router.push('/partners');$parent.curNavName = 'partners'">查看详情</a>
                    </div>
<!--                    <div class="zx-new-partners-list">-->
<!--                        <p>某省银行账户风险联防联控平台</p>-->
<!--                        <a @click="$router.push('/partners');$parent.curNavName = 'partners'">查看详情</a>-->
<!--                    </div>-->
<!--                    <div class="zx-new-partners-list">-->
<!--                        <p style="cursor: pointer" @click="$router.push('/partners');$parent.curNavName = 'partners'">更多…</p>-->
<!--                    </div>-->
                </div>


<!--                <div class="swiper-container swiper-container2">-->
<!--                    <div class="swiper-wrapper">-->
<!--                        <div class="swiper-slide">-->
<!--                            <img src="../../assets/partners-img-1.png" alt="" width="212">-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <img src="../../assets/partners-img-2.jpeg" alt="" width="205">-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <img src="../../assets/partners-img-3.png" alt="" width="230">-->
<!--                        </div>-->
<!--                        <div class="swiper-slide">-->
<!--                            <img src="../../assets/partners-img-4.jpeg" alt="" width="228">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                    &lt;!&ndash; 如果需要导航按钮 &ndash;&gt;-->
<!--                    <div class="swiper-button-prev swiper-button-prev2"></div>-->
<!--                    <div class="swiper-button-next swiper-button-next2"></div>-->
<!--                </div>-->
            </div>
        </div>

        <div class="zx-partners-bar" style="margin-top: 80px; display: flex;flex-direction: column; align-items: center">
            <div class="zx-partners-box" style="
        margin-bottom: 43px; border-bottom: none">
                <div class="zx-partners-title">
                    <h2>关于我们</h2>
                    <h3>About us</h3>
                </div>
            </div>
            <div class="zx-new-aoubt-us-bar" style="width: 1200px; display: flex;flex-direction: column;align-items: flex-start;">
                <h2>公司简介</h2>
                <p style="text-align: left">厦门掌讯信息技术有限公司成立于2010年12月，公司秉持“诚信、求真、敬业、创新”核心理念，深耕于金融行业，推动聚合数据并搭建数据安全流通网络，为金融机构及金融监管提供数据要素服务及场景化应用服务。公司核心团队成员主要来自商业银行、通信运营商以及知名互联网企业等一流的专业人才，对大数据、人工智能等技术在金融行业的应用具有丰富经验和很强的创新能力。</p>

                <div style="display: flex;justify-content: flex-end;width: 100%;">
                    <a @click="$router.push('/aboutUs');$parent.curNavName = 'aboutUs'">了解更多</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import 'swiper/dist/js/swiper'
    import 'swiper/dist/css/swiper.css'
    import Swiper from "swiper"

    export default {
        name: 'App',
        data(){
            return {
                curNavName: '首页',
                navlist:[
                    {
                        name:'首页',
                        id: 'index'
                    },
                    {
                        name:'产品',
                        id: 'product'
                    },
                    {
                        name:'解决方案',
                        id: 'solution'
                    },
                    {
                        name:'客户案例',
                        id: 'partners'
                    },
                    {
                        name:'关于我们',
                        id: 'aboutUs'
                    }
                ]
            }
        },
        mounted() {
            new Swiper('.swiper-container1', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                // autoplay: { //自动开始
                //     delay: 2500, //时间间隔
                //     disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                // },
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next1',
                    prevEl: '.swiper-button-prev1',
                },
            })
            new Swiper('.swiper-container2', {
                direction: 'horizontal', // 垂直切换选项
                //mousewheel: true, //滚轮
                // autoplay: { //自动开始
                //     delay: 2500, //时间间隔
                //     disableOnInteraction: false, //*手动操作轮播图后不会暂停*
                // },
                slidesPerView: 4,
                loop: true, // 循环模式选项
                // pagination: {
                //   el: '.swiper-pagination',
                //   clickable: true, // 分页器可以点击
                // },
                // 如果需要前进后退按钮
                navigation: {
                    nextEl: '.swiper-button-next2',
                    prevEl: '.swiper-button-prev2',
                },
            })
        },
    }
</script>

<style>
    * {
        padding: 0;
        margin: 0;
        list-style: none;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
    }

    html, body {
        position: relative;
        width: 100%;
        height: 100%;
    }


    /*再自定义样式*/
    .swiper-button-prev1 {
        width: 46px;
        height: 77px;
        background: url('../../assets/banner-arr-left.png') no-repeat center center;
        bottom: 15px;
        border: 1px solid rgba(208, 208, 208, 0.25);
    }

    .swiper-button-next1 {
        width: 46px;
        height: 77px;
        background: url('../../assets/banner-arr-right.png') no-repeat center center;
        bottom: 15px;
        border: 1px solid rgba(208, 208, 208, 0.25);
    }
    /*再自定义样式*/
    .swiper-button-prev2 {
        width: 10px;
        height: 19px;
        background: url('../../assets/fo-left.png') no-repeat center center;
        background-size: 100%;
        margin-top: -10px;
    }

    .swiper-button-next2 {
        width: 10px;
        height: 19px;
        background: url('../../assets/fo-right.png') no-repeat center center;
        background-size: 100%;
        margin-top: -10px;
    }
</style>

<style lang="scss" scoped>
    .zx-nav-bar {
        height: 83px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        z-index: 99;
        background: #fff;
        box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
        .zx-nav-box {
            width: 1200px;
            height: 83px;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .zx-nav {
            display: flex;
            height: 83px;
            align-items: center;

            li {
                padding: 0 22px;
                height: 83px;
                line-height: 83px;
                transition: all .5s;
            }
            a{
                text-decoration: none;
                color: #000000;
                width: 100%;
                height: 100%;
                display: inline-block;
            }
            li:hover {
                background: #305DC4;
                color: #fff;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #fff;
                    transition: all .5s;
                }
            }

            li.active {
                background: #305DC4;
                color: #fff;
                cursor: pointer;
                transition: all .5s;
                a{
                    color: #fff;
                    transition: all .5s;
                }
            }
        }
    }

    .zx-banner-bar {
        height: 595px;
    }

    .swiper-container1 {
        height: 100%;
        background: #151419;
    }

    .zx-banner-list {
        position: relative;
        height: 100%;
        overflow: hidden;

        img.banner-1 {
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translate(-1039px, 0);
        }

        img.banner-2 {
            position: absolute;
            left: 50%;
            bottom: 143px;
            transform: translate(-700px, 0);
        }

        .banner-text {
            position: absolute;
            width: 690px;
            left: 45%;
            top: 112px;
            transform: translate(50px, 0);
            display: flex;
            flex-direction: column;

            h3 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 56px;
                line-height: 78px;
                color: #FFFFFF;
                margin-bottom: 20px;

                span {
                    color: #85B3FF;
                }
            }

            p {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 300;
                font-size: 28px;
                line-height: 176.02%;
                color: #FFFFFF;
                text-align: left;
            }
            .button-bar{
                display: flex;
                justify-content: flex-end;
                margin-top: 50px;
            }

            a {
                display: block;
                color: #305DC4;
                width: 194px;
                height: 60px;
                line-height: 60px;
                left: 1554px;
                top: 526px;
                background: #FFFFFF;
                border-radius: 2px;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                color: #305DC4;
                cursor: pointer;
                transition: all .5s;
            }
            a:hover{

                background: #305DC4;
                color: #fff;
                transition: all .5s;
            }
        }
    }

    .zx-product-bar{
        width: 100%;
        height: 750px;
        display: flex;
        justify-content: center;
        background: #ffffff;
    }
    .zx-product-box{
        width: 1200px;
        display: flex;
        align-items: center;
        flex-direction: column;
        .zx-product-title{
            margin-top: 109px;
            margin-bottom: 80px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }
        .zx-product-sub-nav{
            display: flex;
            width: 390px;
            justify-content: space-between;
            margin-bottom: 45px;
            li{
                width: 120px;
                height: 43px;
                line-height: 43px;
                left: 761px;
                top: 974px;
                background: #FFFFFF;
                border: 1px solid rgba(0, 0, 0, 0.1);
                cursor: inherit;
            }
        }
        .zx-product-list{
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 66px;
            li{
                width: 380px;
                height: 391px;
                background: #FFFFFF;
                box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
                display: flex;
                align-items: center;
                flex-direction: column;

                img{
                    width: 380px;
                    height: 195px;
                    margin-bottom: 7px;
                }
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 34px;
                    color: #000000;
                    margin-bottom: 10px;
                }
                p{
                    width: 330px;
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 29px;
                    text-align: center;
                    color: rgba(0, 0, 0, 0.75);
                }
            }
        }
        .more-btn{
            box-sizing: border-box;
            width: 158px;
            height: 41px;
            padding-right: 40px;
            border: 1px solid rgba(0, 0, 0, 0.12);
            filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.25));
            align-items: center;
            line-height: 41px;
            position: relative;
            cursor: pointer;
            transition: all .5s;
            &:hover{
                background-color: #305DC4;
                color: #fff;
                transition: all .5s;
            }
            &::after{
                content: '';
                position: absolute;
                width: 40px;
                height: 40px;
                right: 0px;
                top: -1px;
                background: #000000;
            }
            .array{
                position: absolute;
                right: 10px;
                z-index: 2;
                top: 15px;
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
    }
    .zx-solution-box{
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        .zx-solution-title{
            margin-top: 107px;
            margin-bottom: 64px;
            position: relative;
            h2{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }
            &::after{
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }
            &::before{
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-solution-banner{
            width: 100%;
            height: 548px;
            background-image: url("../../assets/solution-banner.png");
            background-repeat: no-repeat;
            background-size: cover;
        }
        .zx-solution-block{
            width: 100%;
            height: 548px;
            background-color: rgba(34, 53, 96, 0.95);
            display: flex;
            align-items: center;
            flex-direction: column;
        }
        .zx-solution-content{
            width: 1214px;
            height: 394px;
            background: #305DC4;
            transform: translate(0, 226px);
            display: flex;
            justify-content: space-between;
            padding:  0 27px;
            box-sizing: border-box;
            img{
                transform: translate(0, -32px);
            }
            >div{
                width: 506px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                padding-top: 76px;
                margin-right: 50px;
                h3{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 30px;
                    line-height: 1;
                    color: #FFFFFF;
                    margin-bottom: 54px;
                    position: relative;
                    &::after{
                        content: '';
                        width: 42px;
                        height: 2px;
                        background: #FFFFFF;
                        border-radius: 5px;
                        position: absolute;
                        left: 0;
                        top:45px
                    }
                }
                p{
                    font-family: 'PingFang HK';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 180%;
                    color: #FFFFFF;
                    text-align: left;
                    margin-bottom: 30px;
                }
                >div{
                    display: flex;
                    width: 100%;
                    justify-content: flex-end;
                    a{
                        width: 68px;
                        height: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border: 1px solid rgba(255, 255, 255, 0.5);
                        cursor: pointer;
                    }
                }
            }
        }
    }

    .zx-solution-bar{
        width: 100%;
        display: flex;
        justify-content: center;
        background: #fff;
        margin-bottom: 190px;
    }
    .zx-partners-box {
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid #dfdfdf;

        .zx-partners-title {
            margin-bottom: 93px;
            position: relative;

            h2 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 40px;
                line-height: 56px;
                color: #000000;
            }

            h3 {
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 24px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.25);
            }

            &::after {
                content: '';
                position: absolute;
                width: 12px;
                height: 12px;
                background: #305DC4;
                bottom: -20px;
                left: 50%;
                transform: translate(-6px, 0);
                z-index: 2;
            }

            &::before {
                position: absolute;
                content: '';
                width: 658px;
                height: 1px;
                bottom: -15px;
                left: 50%;
                transform: translate(-329px, 0);

                background: #D9D9D9;
            }
        }

        .zx-partners-list{
            display: flex;
            width: 1200px;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 81px;
            botder-bottom: 1px solid rgba(217, 217, 217, 0.9);
        }
    }
    .swiper-container2{
        width: 1200px;
        ::v-deep .swiper-slide{
            height: 69px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
    .zx-footer{
        height: 434px;
        background: #F1F1F1;
        display: flex;
        justify-content: center;
    }

    .zx-footer-box{
        display: flex;padding-right: 100px;box-sizing: border-box;
        width: 800px;
        flex-direction: column;
        >div{
            display: flex;
        }
        .zx-footer-top{
            justify-content: space-between;
            padding-top: 43px;
            margin-bottom: 0px;
        }
        ul{
            display: flex;
            flex-direction: column;
            text-align: left;
            li.title{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 15px;
                line-height: 34px;
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 40px;
            }
            li{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 40px;
                color: rgba(0, 0, 0, 0.5);
            }
        }
    }
    .zx-footer-bottom{
        margin-top: 43px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        >img{
            margin-bottom: 43px;
        }
        p{
            display: flex;
            align-items: center;
            margin-bottom: 43px;
            img{
                margin-right: 10px;
            }
        }
        a{
            list-style: none;
            text-decoration: none;
            color: rgba(0, 0, 0, 0.5);
        }
    }
    .zx-end{
        width: 1200px;
        margin: 0 auto;
        height: 63px;
        display: flex;
        align-items: center;
        font-family: 'PingFang SC';
        font-style: normal;
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: rgba(0, 0, 0, 0.5);
        a{
            font-size: 20px;
            line-height: 28px;
            color: rgba(0, 0, 0, 0.5);
            text-decoration: initial;
            margin-left: 10px;
        }
    }
</style>


<style lang="scss">


    .zx-new-product-bar{
        display: flex;
        justify-content: space-between;
        width: 100%;
        .zx-new-product-list{
            width: 340px;
            height: 440px;
            border: 0.5px solid rgba(0, 0, 0, 0.0);
            transition: all .5s;
            h3{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 30px;
                color: #121212;
                margin-bottom: 20px;
            }
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                color: rgba(18, 18, 18, 0.75);
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                background: rgba(252, 252, 252, 0.5);
                border: 0.5px solid rgba(0, 0, 0, 0.1);
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                border-radius: 4px;
                transition: all .5s;
            }
        }
    }


    .zx-new-partners-list-bar{
        display: flex;
        width: 1060px;
        justify-content: space-between;
        flex-wrap: wrap;
        .zx-new-partners-list{
            width: 520px;
            height: 370px;
            background: #FDFDFD;
            border: 0.5px solid rgba(0, 0, 0, 0.1);
            border-radius: 4px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            transition: all .5s;
            p{
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: normal;
                font-size: 30px;
                color: #2653B8;
                margin-bottom: 15px;
            }
            a{
                display: inline-block;
                width: 130px;
                height: 40px;
                border-radius: 4px;
                border: 1px solid #2653B8;
                font-family: 'PingFang HK';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 40px;
                color: #2653B8;
                cursor: pointer;
                margin-top: 91px;
                &:hover{
                    background: #2653B8;
                    color: #fff;
                    transition: all .5s;
                }
            }
            &:hover{
                box-shadow: 0px 5px 21px rgba(0, 0, 0, 0.1);
                transition: all .5s;
            }
        }
        .zx-new-partners-list:nth-child(1){
            background:url("../../assets/product/parner-bg-1.jpg") no-repeat 68px 40px;
        }
        .zx-new-partners-list:nth-child(2){
            background:url("../../assets/product/parner-bg-2.jpg") no-repeat 107px 54px ;
        }
        .zx-new-partners-list:nth-child(3){
            background:url("../../assets/product/parner-bg-3.png") no-repeat 63px 18px;
        }
        .zx-new-partners-list:nth-child(4){
            background:url("../../assets/product/parner-bg-4.png") no-repeat 121px 76px;
        }
    }


    .zx-new-aoubt-us-bar{
        h2{
            ont-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 30px;
            line-height: 42px;
            color: #000000;
            margin-bottom: 20px;
        }
        p{
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 24px;
            line-height: 214%;
            color: rgba(18, 18, 18, 0.75);
        }
        margin-bottom: 50px;

        a{
            display: inline-block;
            width: 130px;
            height: 40px;
            border-radius: 4px;
            border: 1px solid #2653B8;
            font-family: 'PingFang HK';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 40px;
            color: #2653B8;
            cursor: pointer;
            margin-top: 91px;
            &:hover{
                background: #2653B8;
                color: #fff;
                transition: all .5s;
            }
        }
    }
</style>
